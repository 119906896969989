
.bg-color {
    background-color: rgb(11, 34, 58);
}

.navbar-logo {
    padding-left: 10px;
    height: 35px;
}

.nav-link {
    color: white !important;
}

.nav-link:hover {
    color: rgb(72, 144, 202) !important;
}

.navbar-toggle {
    color: white !important;
}

nav {
    padding-right: 10px;
}

.btn-outline-primary {
    color: rgb( 31, 55, 121 ) !important;
    border-color: rgb( 31, 55, 121 ) !important;
}

.btn-outline-primary:hover {
    color: white !important;
    border-color: rgb( 31, 55, 121 ) !important;
    background-color: rgb( 31, 55, 121 ) !important;
}
