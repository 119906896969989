.carousel-caption {  
    position: absolute;
    top: 50%;
    right: 65%;
    width: 50vh;
    transform: translateY(-50%);
    display: flex;
}

.ids-banner-text {
    color: rgb( 31, 55, 121 );
}

.caption-small {
    margin-left: -90px;
}

.btn-outline-primary {
    color: rgb( 31, 55, 121 ) !important;
    border-color: rgb( 31, 55, 121 ) !important;
}

.btn-outline-primary:hover {
    color: white !important;
    border-color: rgb( 31, 55, 121 ) !important;
    background-color: rgb( 31, 55, 121 ) !important;
}

/* .fade {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 150px;
    z-index: 2;
    background: rgba(255, 255, 255, 0)
        linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 80%
        )
        repeat scroll 0 0;
} */
